
#collection-6616d152b679bf0cb88fa565 figure {
  display: flex !important;
  
  @media (max-width: 767px) {
      flex-direction: column;
  }

  .intrinsic {
    width: 80px !important;
    flex: 0 0 80px;
  }

  .image-card-wrapper {
    flex:  auto;
    width: unset !important;
  }

  p {
    font-size: 16px !important;
    font-family: europa;
  }

  .image-card {
    padding: 0;
    margin: 0;
  }

  .image-title-wrapper,
  .image-subtitle-wrapper {
    @media (max-width: 767px) {
      padding:  0 !important;
    }

    padding: 0 0 0 24px;
  }

  .image-subtitle-wrapper {
    @media (max-width: 767px) {
      margin: 16px 0 0 0 !important;
    }
  }

  .image-title-wrapper {

    @media (max-width: 767px) {
      margin: 16px 0 0 0 !important;
    }
    
    padding: 0 0 0 24px;

    .image-title, p {
      font-size: 24px !important;
      font-family: europa;
      margin-bottom: 16px;
    }

  }

  .image-subtitle {
    margin: 0;
  }
}
