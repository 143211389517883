#collection-6616d152b679bf0cb88fa565 figure {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

@media (max-width: 767px) {
  #collection-6616d152b679bf0cb88fa565 figure {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

#collection-6616d152b679bf0cb88fa565 figure .intrinsic {
  width: 80px !important;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 80px;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
}

#collection-6616d152b679bf0cb88fa565 figure .image-card-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  width: unset !important;
}

#collection-6616d152b679bf0cb88fa565 figure p {
  font-size: 16px !important;
  font-family: europa;
}

#collection-6616d152b679bf0cb88fa565 figure .image-card {
  padding: 0;
  margin: 0;
}

#collection-6616d152b679bf0cb88fa565 figure .image-title-wrapper,
#collection-6616d152b679bf0cb88fa565 figure .image-subtitle-wrapper {
  padding: 0 0 0 24px;
}

@media (max-width: 767px) {
  #collection-6616d152b679bf0cb88fa565 figure .image-title-wrapper,
  #collection-6616d152b679bf0cb88fa565 figure .image-subtitle-wrapper {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  #collection-6616d152b679bf0cb88fa565 figure .image-subtitle-wrapper {
    margin: 16px 0 0 0 !important;
  }
}

#collection-6616d152b679bf0cb88fa565 figure .image-title-wrapper {
  padding: 0 0 0 24px;
}

#collection-6616d152b679bf0cb88fa565 figure .image-title-wrapper .image-title, #collection-6616d152b679bf0cb88fa565 figure .image-title-wrapper p {
  font-size: 24px !important;
  font-family: europa;
  margin-bottom: 16px;
}

#collection-6616d152b679bf0cb88fa565 figure .image-subtitle {
  margin: 0;
}

/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoic3R5bGUuY3NzIiwic291cmNlcyI6WyJzdHlsZS5zY3NzIl0sIm1hcHBpbmdzIjoiQUFDQSxBQUFxQyxvQ0FBRCxDQUFDLE1BQU0sQ0FBQztFQUMxQyxPQUFPLEVBQUUsZUFBZ0I7Q0F3RDFCOztBQXREQyxNQUFNLEVBQUwsU0FBUyxFQUFFLEtBQUs7RUFIbkIsQUFBcUMsb0NBQUQsQ0FBQyxNQUFNLENBQUM7SUFJdEMsY0FBYyxFQUFFLE1BQU87R0FxRDVCOzs7QUF6REQsQUFPRSxvQ0FQa0MsQ0FBQyxNQUFNLENBT3pDLFVBQVUsQ0FBQztFQUNULEtBQUssRUFBRSxlQUFnQjtFQUN2QixJQUFJLEVBQUUsUUFBUztDQUNoQjs7QUFWSCxBQVlFLG9DQVprQyxDQUFDLE1BQU0sQ0FZekMsbUJBQW1CLENBQUM7RUFDbEIsSUFBSSxFQUFHLElBQUs7RUFDWixLQUFLLEVBQUUsZ0JBQWlCO0NBQ3pCOztBQWZILEFBaUJFLG9DQWpCa0MsQ0FBQyxNQUFNLENBaUJ6QyxDQUFDLENBQUM7RUFDQSxTQUFTLEVBQUUsZUFBZ0I7RUFDM0IsV0FBVyxFQUFFLE1BQU87Q0FDckI7O0FBcEJILEFBc0JFLG9DQXRCa0MsQ0FBQyxNQUFNLENBc0J6QyxXQUFXLENBQUM7RUFDVixPQUFPLEVBQUUsQ0FBRTtFQUNYLE1BQU0sRUFBRSxDQUFFO0NBQ1g7O0FBekJILEFBMkJFLG9DQTNCa0MsQ0FBQyxNQUFNLENBMkJ6QyxvQkFBb0I7QUEzQnRCLEFBNEJFLG9DQTVCa0MsQ0FBQyxNQUFNLENBNEJ6Qyx1QkFBdUIsQ0FBQztFQUt0QixPQUFPLEVBQUUsVUFBVztDQUNyQjs7QUFMQyxNQUFNLEVBQUwsU0FBUyxFQUFFLEtBQUs7RUE3QnJCLEFBMkJFLG9DQTNCa0MsQ0FBQyxNQUFNLENBMkJ6QyxvQkFBb0I7RUEzQnRCLEFBNEJFLG9DQTVCa0MsQ0FBQyxNQUFNLENBNEJ6Qyx1QkFBdUIsQ0FBQztJQUVwQixPQUFPLEVBQUcsWUFBYTtHQUkxQjs7O0FBR0MsTUFBTSxFQUFMLFNBQVMsRUFBRSxLQUFLO0VBckNyQixBQW9DRSxvQ0FwQ2tDLENBQUMsTUFBTSxDQW9DekMsdUJBQXVCLENBQUM7SUFFcEIsTUFBTSxFQUFFLHFCQUFzQjtHQUVqQzs7O0FBeENILEFBMENFLG9DQTFDa0MsQ0FBQyxNQUFNLENBMEN6QyxvQkFBb0IsQ0FBQztFQUVuQixPQUFPLEVBQUUsVUFBVztDQVFyQjs7QUFwREgsQUE4Q0ksb0NBOUNnQyxDQUFDLE1BQU0sQ0EwQ3pDLG9CQUFvQixDQUlsQixZQUFZLEVBOUNoQixBQThDa0Isb0NBOUNrQixDQUFDLE1BQU0sQ0EwQ3pDLG9CQUFvQixDQUlKLENBQUMsQ0FBQztFQUNkLFNBQVMsRUFBRSxlQUFnQjtFQUMzQixXQUFXLEVBQUUsTUFBTztFQUNwQixhQUFhLEVBQUUsSUFBSztDQUNyQjs7QUFsREwsQUFzREUsb0NBdERrQyxDQUFDLE1BQU0sQ0FzRHpDLGVBQWUsQ0FBQztFQUNkLE1BQU0sRUFBRSxDQUFFO0NBQ1giLCJuYW1lcyI6W119 */

/*# sourceMappingURL=style.css.map */
